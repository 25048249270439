import { admin2 } from './instance'

// get
export const FindMemberBatchTask = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberBatchTask/${id}`,
  })
}

// get
export const GetMemberBatchTask = async ({
  shopId,
  start,
  limit,
  type,
  status,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberBatchTask`,
    params: {
      start,
      limit,
      type,
      status,
    },
  })
}

// count
export const GetMemberBatchTaskCount = async ({
  shopId,
  type,
  status,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberBatchTask/count`,
    params: {
      type,
      status,
    },
  })
}

// get members
export const GetMemberBatchListTask = async ({
  shopId,
  id,
  start,
  limit,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberBatchTaskList/${id}/members`,
    params: {
      start,
      limit,
    },
  })
}

// count members
export const GetMemberBatchTaskListCount = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberBatchTaskList/${id}/members/count`,
  })
}

// find member by conditions
export const GetMemberBatchTaskByConditions = async ({
  shopId,
  condition,
  start,
  limit,
  memberId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/memberBatchTask/findMemberByCondition`,
    data: {
      condition,
      start,
      limit,
      memberId,
    },
  })
}

// find member by conditions
export const CountMemberBatchTaskByConditions = async ({
  shopId,
  condition,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/memberBatchTask/countMemberByCondition`,
    data: {
      condition,
    },
  })
}

// create
export const CreateMemberBatchTask = async ({
  shopId,
  type,
  settings,
  condition,
  planExecutionAt,
  note,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/memberBatchTask`,
    data: {
      type,
      settings,
      condition,
      planExecutionAt,
      note,
    },
  })
}
// 批次年度錢包 api
export const GetMembersBatchPointYearlyWallet = async ({ shopId, year, memberIds, shopPointKey }) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/memberBatchPointYearlyExtensionTask/batchFindYearExpirations`,
    data: { year, memberIds, shopPointKey },
  })
}

export const GetMembersBatchCashbackYearlyWallet = async ({ shopId, year, memberIds }) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/memberBatchCashbackYearlyExtensionTask/batchFindYearExpirations`,
    data: { year, memberIds },
  })
}
